import { precacheAndRoute } from 'workbox-precaching';

// Add custom service worker logic, such as a push notification service, or json request cache.
self.addEventListener('message', (event: any) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});

// TODO: Adding the header to all request in our scope, we can add the token header.
// self.addEventListener('fetch', (event: any) => {
//     event.respondWith(
//         fetch(event.request, {
//             mode: 'cors',
//             credentials: 'include',
//             headers: {
//                 'Authorization': 'Bearer mF_9.B5f-4.1JqM' // of course, not hard-code this here...
//             }
//         })
//     )
// });

try {
  precacheAndRoute([]);
} catch (err) {
  console.info('if you are in development mode this error is expected: ', err);
}
